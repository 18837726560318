import React from 'react'
import Layout from '../components/layout'
import Slider from '../components/slider'
import { useStaticQuery, graphql } from 'gatsby'
import Header from '../components/header'

const CreativePage = () => {
    const data = useStaticQuery(graphql`
        {
            allClientsJson {
                nodes {
                    id
                    image {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                    title
                    link
                    category
                }
            }
        }
    `)
    const { nodes } = data.allClientsJson

    const filteredNodes = nodes.filter((node) => node.category === 'creative')

    return (
        <Layout
            title="Social Hustle Creative Portfolio"
            description="Discover the creativity and innovation behind our projects with this collection of our most visually impactful and artistic work."
        >
            <Slider nodes={filteredNodes} />
        </Layout>
    )
}

export default CreativePage
